@import "../../../../css_variables/main-vars";

.HomePageWidgets {
  width: 100%;
  height: 62px;
  @include flex-all-center;
  gap: 8px;
  margin-bottom: calc(0px - $home-page-gap);
  overflow: visible;

  @include media-mobile-small-maximum-374 {
    height: 82px;
    gap: 2px;
  }
}