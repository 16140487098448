@import "../../../../css_variables/main-vars";

.PartnersContainer {
  width: 40vw;
  min-width: 700px;
  @include flex-column-all-center;
  @include user-select-none;
  gap: 8px;

  @include media-tablet-maximum-1023 {
    width: 85vw;
    min-width: 0;
  }

  @include media-mobile-medium-maximum-424 {
    width: 95vw;
    min-width: 0;
  }
}