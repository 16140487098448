@import "../../../../../../../../css_variables/main-vars";

.RegistrationSendReturnButton {
  @include text-adaptation;

  & {
    @include styled-button;
    @include user-select-none;
    position: absolute;
    top: -120%;
  }

  &.clickable {
    @include hover-cursor-pointer;
  }

  img {
    height: 18px;
  }
}