@import "../../css_variables/main-vars";
@import "../../css_variables/media-queries";

.SystemButtons {
  height: $system-buttons-panel-height + px;
  @include flex-line-end-vertical-center;
  @include text-adaptation;
  
  & {
    gap: 10px;
    position: absolute;
    top: 12px;
    right: 10px;
    z-index: 1;
  }
}