@import "../../../../../../css_variables/main-vars";

.PasswordRecoveryModeDisabler {
  width: 100%;
  @include flex-column-line-end-vertical-center;
  margin-bottom: calc(-1 * $login-section-gap / 2);

  span {
    @include clickable-link;
  }
}