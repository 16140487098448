@import "../../../../../css_variables/main-vars";

.HomePageWidget {
  height: 75%;
  @include flex-column-all-center;
  flex-wrap: wrap;
  gap: 8px;
  border-width: 1px;
  border-style: solid;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding-inline: 12px;
  z-index: 1;

  @include hover {
    transform: translateY(-10px);
  }

  &:not(.mainAppWidget) {
    @include media-mobile-small-maximum-374 {
      padding-inline: 12px;
      margin-inline: 8px;
    }
  }

  &.mainAppWidget {
    height: 100%;
    gap: 3px;
  }

  .widgetMainText {
    width: 100%;
    text-align: center;
    color: $text-color-dark;
    font-size: 18px;
    line-height: 18px;

    @include media-mobile-big-maximum-767 {
      font-size: 16px;
      line-height: 16px;
    }

    @include media-mobile-medium-maximum-424 {
      font-size: 15px;
      line-height: 15px;
    }

    // @include media-mobile-small-maximum-374 {
    //   font-size: 14px;
    //   line-height: 14px;
    // }

    &.mainApp {
      color: $main-theme-color-remastered;
      font-style: italic;
      font-weight: bold;
      font-size: 25px;
      line-height: 25px;

      @include media-mobile-big-maximum-767 {
        font-size: 23px;
        line-height: 23px;
      }

      @include media-mobile-medium-maximum-424 {
        font-size: 22px;
        line-height: 22px;
      }

      // @include media-mobile-small-maximum-374 {
      //   font-size: 21px;
      //   line-height: 21px;
      // }
    }
  }

  .mainAppWidgetDescriptionText {
    width: 100%;
    text-align: center;
    color: $secondary-text-color;
    line-height: 16px;

    @include media-mobile-big-maximum-767 {
      font-size: 14px;
      line-height: 14px;
    }

    @include media-mobile-medium-maximum-424 {
      font-size: 13px;
      line-height: 13px;
    }

    // @include media-mobile-small-maximum-374 {
    //   font-size: 12px;
    //   line-height: 12px;
    // }
  }
}