@import "../../../../../css_variables/main-vars";

.PartnersLogos {
  width: 100%;
  height: 52px;
  @include flex-all-center;
  gap: 12px;
  flex-wrap: wrap;

  img {
    height: 100%;
    transition: filter $page-change-duration ease-in-out;

    @include hover {
      filter: brightness(130%) drop-shadow(0 0 1px #555);
    }
  }
}