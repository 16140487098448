@import "../../../../css_variables/main-vars";

.MainLogoText {
  @include flex-all-center;
  font-size: 24px;
  font-weight: bold;
  color: $text-color-dark;
  opacity: 0;
  text-transform: uppercase;
  opacity: 1;

  span {
    font-size: 0.6rem;
  }
}