@import "../../../../../css_variables/main-vars";

.UserSideMenuHead {
  width: 100%;
  @include flex-line-spacebetween-vertical-center;
  @include text-adaptation;

  & {
    padding-block: 10px;
  }

  .userNicknameSpan {
    @include user-select-none;
    color: $accents-theme-color-remastered;
    font-weight: bold;
    font-size: 24px;
  }

  .closeSideMenuSpan {
    @include hover-cursor-pointer;

    & {
      @include user-select-none;
      color: $text-color-dark;
      font-weight: bold;
      font-size: 20px;
    }
  }
}