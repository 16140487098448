@import "../../../../css_variables/main-vars";

.Loader {
  width: 30px;
  height: 30px;
  @include flex-all-center;
  border-radius: 50%;
  background: linear-gradient($background-color-dark 50%, $main-theme-color-remastered 95%);
  opacity: 0;
  transition: opacity 0.5s linear;
  animation: endless-rotation 5s ease-in-out infinite;
  z-index: 1;

  &.shown {
    opacity: 1;
    transition: opacity 2.5s linear;
  }

  .point {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: $background-color-dark;
  }

  @keyframes endless-rotation {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
}