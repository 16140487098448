@import "../../css_variables/main-vars";

.AuthorizationDataValidationInfo {
  width: 100%;
  @include flex-line-start-vertical-center;
  gap: 4px;

  img {
    height: 1.2em;
  }

  p {
    vertical-align: bottom;
    @include flex-line-start-vertical-top;
    color: $negative-color;
    line-height: 1em;
    text-transform: lowercase;
  }
}