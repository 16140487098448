@import "../../../../css_variables/main-vars";
@import "../../../../css_variables/media-queries";

.MainLogoLetter {
  @include flex-all-center;

  &.logoMode {
    span {
      font-size: 3rem;
    }
  }

  span {
    font-size: 120px;
    font-weight: bold;
    color: $main-theme-color-remastered;


    @include media-mobile-big-maximum-767 {
      font-size: 110px;
    }

    @include media-mobile-medium-maximum-424 {
      font-size: 100px;
    }

    @include media-mobile-small-maximum-374 {
      font-size: 90px;
    }
  }
}