@import "../../../../../css_variables/main-vars";

.UserSideMenuSectionItem {
  width: 100%;
  @include text-adaptation;

  & {
    @include user-select-none;
  }

  span {
    @include hover-cursor-pointer;

    & {
      color: $main-theme-color-remastered;
    }
  }
}