@import "../../../../css_variables/main-vars";

.NavLinksGroup {
  height: $system-buttons-panel-height + px;
  position: relative;

  .homeButtonWrapper {
    width: 100%;
    position: absolute;
    @include flex-all-center;
  }

  .linksWrapper {
    width: 100%;
    @include flex-column-all-center;
    gap: 4px;

    .secondaryLinksWrapper {
      width: 100%;
      @include flex-column-all-center;
      gap: 3px;
    }
  }
}