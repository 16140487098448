@import "./media-queries";

// colors
$main-theme-color-remastered: #3190cf;
$accents-theme-color-remastered: #9031cf;
$background-letter-color: #3b444b; // arsenic
$background-color-dark: #090909;
$background-color: #d3d3d3;
$background-container-color-dark: #151515;
// text-colors
$text-color: #090909;
$text-color-dark: #d3d3d3;
$secondary-text-color: #555;
$copyright-text-color-dark: #999;

// system message colors
$positive-color: #31cf50;
$negative-color:#cf3151;
$warning-color:#cfcf31;
$authorization-page-background: #fff;

// sizes
$auth-input-default-height: 30;
$settings-buttons-container-height: 55;
$page-padding: 46px 10px 12px 13px;
$user-room-header-default-height: 35;
$user-room-content-top-padding: 80;
$page-sections-explorer-height: 500;
$page-sections-explorer-width: 75vw;
$page-section-not-active-width: 275;
$page-section-not-active-portable-height: 95;
$system-message-height: 36;
$system-message-paddings: 2px 15px;
$user-actions-button-width: 8;
$user-actions-button-height: 17;
$user-actions-button-active-width: 6;
$system-buttons-panel-height: 19;
$registration-question-padding: 15px;
$login-section-padding: 15px 35px 0px 35px;
$login-section-bottom-padding: 15px;
$login-section-gap: 15px;
$authorization-apply-section-height: 40px;
$button-remastered-inline-paddings: 25px;
$button-remastered-wide-min-width: 180px;
$home-page-gap: 20px;
$page-switcher-height: 90;
$page-switcher-side-gap: 27px;

// durations
$page-change-duration: 400ms;

// svg
$full-circle-dash-offset: 283;

// mixins
@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin hover-cursor-pointer {
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      ;
    }
  }
}

@mixin password-visibility-toggler {
  height: 50%;
  @include hover-cursor-pointer;

  & {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 10px;
  }
}

@mixin container-appearance {
  border-radius: 8px;
  box-shadow: inset 0 0 5px 2px rgba($accents-theme-color-remastered, 0.1);
  background-color: $background-container-color-dark;
}

@mixin modal-window-external-container {
  @include flex-all-center;
  background-color: $background-container-color-dark;
  border-radius: 15px;
}

@mixin modal-window-internal-container {
  @include flex-all-center;
  background-color: $background-color-dark;
  border: 1px solid $accents-theme-color-remastered;
  box-shadow: 0 0 1px 1px $accents-theme-color-remastered;
  border-radius: 10px;
}

@mixin ellipsize {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin text-adaptation {
  @include media-mobile-small-maximum-374 {
    font-size: 13.5px;
  }
}

@mixin page-defaults {
  width: 100%;
  height: 100vh;
  @include flex-all-center;
  @include text-adaptation;

  & {
    color: $text-color-dark;
  }
}

// flex mixins
// flex row mixins
@mixin flex-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-line-center-vertical-top {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@mixin flex-line-center-vertical-bottom {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@mixin flex-line-start-vertical-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-line-start-vertical-top {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flex-line-end-vertical-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-line-end-vertical-top {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

@mixin flex-line-spacebetween-vertical-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-vertical-center {
  display: flex;
  align-items: center;
}

// flex column mixins
@mixin flex-column-all-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin flex-column-line-center-vertical-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@mixin flex-column-line-start-vertical-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@mixin flex-column-line-center-vertical-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

@mixin flex-column-line-start-vertical-center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

@mixin flex-column-line-end-vertical-center {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

@mixin flex-column-line-end-vertical-top {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

@mixin flex-column-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin flex-column-line-center-vertical-spacebetween {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

// other mixins
@mixin hidden-scroll {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin user-select-none {
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

@mixin scroll {
  & {
    scrollbar-width: thin;
    scrollbar-color: $main-theme-color rgba(0, 0, 0, 0.2);
    scrollbar-gutter: stable;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    width: 5px;
    height: 5px;
    background-color: $main-theme-color;
    border-radius: 5px;
  }
}

@mixin general-button {
  @include flex-all-center;
  border: 1px solid #000;
  background-color: $main-theme-color;
  color: #fff;
  transition: background-color 0.5s linear;
  cursor: pointer;

  &:disabled {
    background-color: #888;
    cursor: default !important;
  }

  @include hover {
    &:not(:disabled) {
      background-color: $secondary-theme-color;
    }
  }
}

@mixin styled-button {
  @include flex-all-center;
  gap: 8px;
  border-radius: 10px;
  box-shadow: inset 0 0 8px 4px $background-container-color-dark;
  padding: 10px 18px;
}

@mixin styled-input {
  width: 100%;
  height: 100%;
  border: 1px solid $accents-theme-color-remastered;
  border-radius: 3px;
  padding-left: 5px;
  padding-block: 8px;
  color: $accents-theme-color-remastered;
  background-color: $background-color-dark;
  font-weight: bold;
}

@mixin clickable-link {
  @include hover-cursor-pointer;

  & {
    font-weight: bold;
    font-style: italic;
    text-transform: lowercase;
    color: $main-theme-color-remastered;
  }
}