@import "../../../../css_variables/main-vars";
@import "../../../../css_variables/media-queries";

.AssistantIntroduction {
  width: 50%;
  height: 300px;
  @include flex-column-all-center;
  color: $text-color-dark;
  border: 1px solid $accents-theme-color-remastered;
  border-top: none;
  border-bottom: none;
  border-radius: 10px;

  @include media-mobile-big-maximum-767 {
    width: 90%;
  }
}