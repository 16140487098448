@import "../../../../../../../../css_variables/main-vars";

.LanguageSelector {
  opacity: 0;
  font-weight: bold;
  color: #bbb;

  &.current {
    color: $main-theme-color-remastered;
  }
}