@import "../../../css_variables/main-vars";
@import "../../../css_variables/media-queries";

.UserSideMenuContainer {
  width: 320px;
  height: 100%;
  @include flex-column-line-center-vertical-top;
  @include container-appearance;
  gap: 8px;
  position: absolute;
  right: 0;
  padding: 10px 20px;

  @include media-mobile-medium-maximum-424 {
    width: 100%;
  }
}