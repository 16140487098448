@import "../../../../../../css_variables/main-vars";

.LanguageItem {
  min-width: 100%;
  @include flex-all-center;
  @include hover-cursor-pointer;

  & {
    will-change: transform;
  }

  span {
    text-transform: uppercase;
    font-weight: bold;
  }
}