@import "../../../../../../css_variables/main-vars";

.PasswordRecoveryModeSwitcher {
  width: 100%;
  @include flex-line-end-vertical-center;
  @include text-adaptation;

  & {
    margin-bottom: calc(-1 * $login-section-gap / 2);
  }

  span {
    @include user-select-none;
    @include clickable-link;
  }
}