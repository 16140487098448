@import "../../../../../css_variables/main-vars";

.PartnersHeader {
  width: 100%;
  @include flex-all-center;
  gap: 10px;

  span {
    color: $accents-theme-color-remastered;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .partnersWing {
    width: 100%;
    height: 3px;
    background: linear-gradient(to right, $accents-theme-color-remastered 40%, transparent 100%);

    &:first-of-type {
      transform: rotate(180deg);
    }
  }
}