@import "../../../../../../css_variables/main-vars";

.NavLink {
  height: 100%;
  @include flex-all-center;
  @include user-select-none;
  gap: 6px;

  @include hover {
    img {
      filter: drop-shadow(0 0 1px $warning-color) brightness(150%) contrast(150%);
    }
  }

  &.notClickable {
    pointer-events: none;
  }

  &.appLink {
    font-style: italic;
    font-weight: bold;
  }

  img {
    height: 1.1em;
    min-width: 1.1em;
    transition: filter $page-change-duration linear;
  }
}