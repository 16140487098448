@import "../../../../css_variables/main-vars";

.LanguageSwitcher {
  height: $system-buttons-panel-height + px;
  @include flex-line-center-vertical-top;
  @include user-select-none;

  .languagesWrapper {
    width: 100%;
    height: 100%;
    @include flex-line-start-vertical-top;
    overflow: hidden;
    color: $text-color-dark;
  }
}