@import "../../../../../../../../css_variables/main-vars";

.LoginInput {
  width: 100%;
  height: $authorization-apply-section-height;
  position: relative;

  input {
    @include styled-input;
  }
}