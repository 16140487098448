@import "../../css_variables/main-vars";

.TextStyled {
  width: 100%;

  &.centered {
    text-align: center;
  }

  a {
    color: $main-theme-color-remastered;
    font-style: italic;
    text-decoration: underline;
  }
}