@import "../../../../../../css_variables/main-vars";

.CodeConfirmationBlock {
  width: 100%;
  @include flex-column-line-center-vertical-top;
  gap: $login-section-gap;

  input {
    height: $authorization-apply-section-height;
  }
}