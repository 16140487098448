@import "../../../../css_variables/main-vars";

.NotificationMessage {
  min-width: 75vw;
  height: 100%;
  @include flex-column-line-start-vertical-center;
  @include user-select-none;
  color: $accents-theme-color-remastered;

  &.positive {
    color: $positive-color;
  }

  &.negative {
    color: $negative-color;
  }

  &.warning {
    color: $warning-color;
  }

  .NotificationMessageText {
    width: 100%;
    font-weight: bold;

    .NotificationMessageTextLetter {
      text-transform: lowercase;
      opacity: 0;
      font-size: 14px;
    }
  }
}