@import '../../css_variables/main-vars';

.Notificator {
  width: 33px;
  height: 33px;
  @include flex-line-start-vertical-center;
  gap: 10px;
  position: absolute;
  bottom: 25px;
  left: 25px;
  overflow: visible;
  z-index: 1;
}