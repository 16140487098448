@import "../../css_variables/main-vars";
@import "../../css_variables/media-queries";

.UserSideMenu {
  width: 100%;
  height: 100%;
  @include flex-column-line-start-vertical-top;
  @include text-adaptation;

  .background {
    width: 100%;
    height: 100%;
  }

  & {
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    z-index: 9996;
  }
}