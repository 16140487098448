@import "../../../../../../../../css_variables/main-vars";

.RegistrationQuestionSwitcher {
  width: 100%;
  @include flex-column-all-center;
  @include user-select-none;
  gap: 5px;
  overflow: hidden;

  &.clickable {
    @include hover-cursor-pointer;
  }

  p {
    width: 100%;
    text-align: center;
    font-size: 14px;

    &.appliedInfoText {
      height: 1rem;
      @include ellipsize;
      color: $accents-theme-color-remastered;
    }

    &.title {
      color: $secondary-text-color;
      text-transform: lowercase;
    }
  }

  .indicatorWrapper {
    width: 100%;
    height: 2px;
    position: relative;
    background-color: rgba($color: $secondary-text-color, $alpha: 0.5);

    .indicator {
      width: 0;
      height: 100%;
      background-color: $main-theme-color-remastered;
      border-radius: 1px;
    }
  }
}