@import "../../../../../../css_variables/main-vars";
@import "../../../../../../css_variables/media-queries";

.RegistrationQuestion {
  width: 100%;
  @include flex-column-line-start-vertical-top;
  @include user-select-none;
  @include container-appearance;
  @include text-adaptation;

  & {
    gap: 15px;
    padding: $registration-question-padding;
  }
}