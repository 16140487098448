  @import "../../../../css_variables/main-vars";

  .gradient {
    background: linear-gradient(to right, $accents-theme-color-remastered, $text-color-dark);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  .solid {
    color: $accents-theme-color-remastered;
  }