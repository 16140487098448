@import "./css_variables/main-vars";

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#webpack-dev-server-client-overlay {
  display: none !important
}

a {
  color: inherit;
  text-decoration: none;
}

input {
  &:focus {
    outline: none;
  }
}

body {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: $background-color-dark;
  overflow-x: hidden;
}

button {
  &:not(:disabled) {
    cursor: pointer;
  }
}