@import "../../css_variables/main-vars";

.ErrorPage {
  @include page-defaults;

  .error-container {
    @include flex-column-line-start-vertical-top;
    gap: 5px;
    padding: 15px;
    box-shadow: 0 0 4px 3px $negative-color;
    border-radius: 10px;

    h1 {
      font-size: 20px;
      font-weight: bold;
      color: $negative-color;
    }

    p {
      font-weight: bold;
      font-size: 0.9em;
      color: $main-theme-color-remastered;
    }
  }
}