@import "../../../../../../../../css_variables/main-vars";

.RegistrationQuestionAnswer {
  width: 100%;
  height: 100%;
  @include flex-line-start-vertical-center;
  position: relative;

  input {
    @include styled-input;
  }
}