@import "../../../../css_variables/main-vars";
@import "../../../../css_variables/media-queries";

.RegistrationSection {
  width: 50%;
  height: 210px;
  @include flex-column-line-center-vertical-top;
  gap: 5px;
  position: relative;

  @include media-laptop-maximum-1439 {
    width: 85%;
  }

  @include media-mobile-big-maximum-767 {
    width: 87%;
  }
}