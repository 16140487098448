@import "./css_variables/main-vars";

.page {
  position: relative;
}

.page-enter {
  opacity: 0;
  transform: scale(0.85);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity $page-change-duration, transform $page-change-duration;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.85);
  transition: opacity $page-change-duration, transform $page-change-duration;
}