@import "../../css_variables/main-vars";

.ButtonRemastered {
  height: $authorization-apply-section-height;
  @include flex-all-center;
  @include user-select-none;
  position: relative;
  border: none;
  border-radius: 3px;
  padding-inline: $button-remastered-inline-paddings;
  font-weight: bold;

  &.wideButton {
    min-width: $button-remastered-wide-min-width;
  }

  &.fullWidth {
    width: 100%;
  }
}