@import "../../css_variables/main-vars";
@import "../../css_variables/media-queries";

.ModalWindow {
  width: 100%;
  height: 100%;
  @include flex-all-center;
  top: 0;
  z-index: 9997;

  & {
    position: absolute;
  }

  .modalWindowBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    backdrop-filter: blur(7px) saturate(170%);
    -webkit-backdrop-filter: blur(7px) saturate(170%);
  }

  .modalWindowContainerWrapper {
    @include modal-window-external-container;
    @include user-select-none;
    padding: 5px;
    position: relative;
    z-index: 9998;

    .modalWindowContainer {
      width: 400px;
      min-height: 150px;
      @include modal-window-internal-container;

      @include media-mobile-big-maximum-767 {
        width: 85vw;
      }

      p {
        width: 100%;
        height: 100%;
        text-align: center;
        vertical-align: middle;
        color: $text-color-dark;
      }
    }

    .closeModalWindowButtonWrapper {
      width: 50px;
      height: 35px;
      @include modal-window-external-container;
      @include hover-cursor-pointer;

      & {
        transform: translateY(-12px);
        position: absolute;
        top: 0;
        right: 25px;
        padding: 5px;
        z-index: 9999;
      }

      .closeModalWindowButton {
        width: 100%;
        height: 100%;
        @include modal-window-internal-container;
        position: relative;

        @include hover {
          img {
            filter: drop-shadow(0 0 3px $negative-color) brightness(150%) contrast(150%);
          }
        }

        img {
          height: 55%;
          transition: filter $page-change-duration linear;
        }
      }
    }

    .applyModalWindowButtonContainer {
      width: 100%;
      @include flex-all-center;
      position: absolute;
      bottom: 0;

      .applyModalWindowButtonWrapper {
        width: 80px;
        height: 60px;
        @include modal-window-external-container;
        @include hover-cursor-pointer;

        & {
          transform: translateY(39%);
          padding: 5px;
          z-index: 9999;
        }

        .applyModalWindowButton {
          width: 100%;
          height: 100%;
          @include modal-window-internal-container;
          position: relative;

          @include hover {
            img {
              filter: drop-shadow(0 0 5px $positive-color) brightness(150%) contrast(150%);
            }
          }

          img {
            height: 65%;
            transition: filter $page-change-duration linear;
          }
        }
      }
    }
  }
}