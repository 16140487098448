@import "../../../../../../css_variables/main-vars";

.UserSideMenuOpener {
  @include flex-all-center;
  @include user-select-none;
  position: relative;
  z-index: 1;

  span {
    color: $accents-theme-color-remastered;
    font-weight: bold;
  }
}