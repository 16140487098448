@import "../../../../css_variables/main-vars";
@import "../../../../css_variables/media-queries";

.LoginSection {
  width: 25%;
  max-width: 350px;
  height: 410px;
  @include flex-column-line-center-vertical-top;
  @include container-appearance;
  gap: $login-section-gap;
  padding: $login-section-padding;

  @include media-laptop-maximum-1439 {
    width: 55%;
  }

  @include media-mobile-big-maximum-767 {
    width: 87%;
  }
}